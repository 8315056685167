import React, { useState } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { Modal, Button, TextInput, Label } from 'flowbite-react';
import { toast } from 'react-toastify';

const SectionRemember = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const notify = () =>
    toast.success("Malumotlaringiz jo'natildi", { position: 'top-center' });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    notify();
    setIsOpen(false);
  };

  return (
    <>
      <div className='w-full h-screen main_container'>
        <div>
          <h1 className='title_section'>{t('titlemain')}</h1>
          <button className='bron_button' onClick={openModal}>
            <a style={{ color: 'white', zIndex: '9999' }}>{t('bron')}</a>
          </button>
        </div>
      </div>

      <Modal show={isOpen} onClose={closeModal}>
        <Modal.Header>Tur uchun joy banq qilish</Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='base' value='Ismingiz' />
              </div>
              <TextInput id='base' type='text' sizing='md' />
            </div>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='base' value='Telfon raqamingiz' />
              </div>
              <TextInput id='base' type='text' sizing='md' />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Ma'lumotlarni yuborish</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SectionRemember;
