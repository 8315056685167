import React from 'react';
import Slider from 'react-slick';
import './style.css';
import tourist1 from '../../assets/image/umra1.jpg';
import tourist2 from '../../assets/image/umra2.jpg';
import tourist3 from '../../assets/image/umra3.jpg';
import { useTranslation } from 'react-i18next';

const About = ({ refMy }) => {
  const data = [tourist1, tourist2, tourist3];
  const { t } = useTranslation();

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    arrows: false,
    adaptiveHeight: true,

    slidesToScroll: 1,
    className: 'slider variable-width',
    // dotsClass: 'slick-dots',
    // centerPadding:'100px',
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          arrows: false,
          adaptiveHeight: true,

          dots: true,
          // dotsClass: 'dots-custom',
          customPaging: function (i) {
            return <div className='des__dots'></div>;
          },
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,

          infinite: true,
          dots: true,
          customPaging: function (i) {
            return <div className='des__dots'></div>;
          },
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,

          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          className: 'slider variable-width custom__height',
          slidesToShow: 2,
          variableWidth: true,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          adaptiveHeight: true,

          // dots: true,
        },
      },
    ],
  };
  return (
    <div className='ab__container hidden-class' ref={refMy}>
      <div className='ab__title'>
        <span className='text-color'> {t('About')}</span> {t('Us')}
      </div>
      <div className='ab__text'>{t('ab_des')}</div>
      <div className='ab__cards'>
        <div className='ab_custom--card'>
          <a style={{ cursor: 'pointer' }}>
            <div
              className='ab__card'
              style={{
                backgroundImage: `url(${tourist1})`,
              }}
            />
          </a>
          <a style={{ cursor: 'pointer' }}>
            <div
              className='ab__card'
              style={{
                backgroundImage: `url(${tourist2})`,
              }}
            />
          </a>
          <a style={{ cursor: 'pointer' }}>
            <div
              className='ab__card'
              style={{
                backgroundImage: `url(${tourist3})`,
              }}
            />
          </a>
        </div>
        <div className='ab__slide'>
          <Slider {...settings}>
            {[1, 2, 4].map((item, ind) => (
              <a style={{ cursor: 'pointer' }}>
                <div
                  className='ab__card'
                  style={{
                    backgroundImage: `url(${data[ind]})`,
                  }}
                />
              </a>
            ))}
          </Slider>
        </div>
      </div>
      <div className='ab__more--text'>
        Mabrur Travel - muhabbatli insonlar tanlovi!
      </div>
      {/* <div className='ab__statis'>
        <div className='ab__statis--card'>
          <span className='text-color ab__statis--title'>+1.5k</span>
          <p className='ab__statis--text'>{t('Explores')}</p>
        </div>
        <div className='ab__statis--card '>
          <span className='text-color ab__statis--title'>260</span>
          <p className='ab__statis--text'>{t('Destinations')}</p>
        </div>
        <div className='ab__statis--card'>
          <span className='text-color ab__statis--title'>800</span>
          <p className='ab__statis--text'>{t('Experience')}</p>
        </div>
      </div> */}
    </div>
  );
};

export default About;
