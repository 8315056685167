import React, { useRef } from 'react';
import './style.css';
import Button from '../Generic/Button';
import Phone from '../../assets/icons/phone2.svg';
import Email from '../../assets/icons/email.svg';
import Telegram from '../../assets/icons/Telegram.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Youtube from '../../assets/icons/yotube.svg';
import Vector from '../../assets/image/payme_01.svg';
import payme from '../../assets/icons/payme.svg';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
const Contact = ({ refMy }) => {
  const { t } = useTranslation();

  const createPaymeOrder = async (params) => {
    const response = await axios.post('https://checkout.paycom.uz/api', {
      method: 'CreateOrder',
      params,
    });

    return response.data;
  };

  return (
    <div className='con__container hidden-class' ref={refMy}>
      <div className='con__right'>
        {/* <div className='con_attach right-payme'>
          <a className='payme' href='https://payme.uz/64365b087044c9d1c4068979'>
            <img src={Vector} alt='' />
          </a>
        </div> */}
        <div className='con__title'>
          {t('Contact')} <span className='text-color'>{t('boglan')}</span>
        </div>
        <div className='con__text'>{t('form')}</div>
        <div className='con__set'>
          <div className='con__phone'>
            <img src={Phone} alt='' />
            <span className='con__number'>+998 94 117-72-72</span>
          </div>
          <div className='con__mail'>
            <img src={Phone} alt='' />
            <span className='con__number'>+998 33 168-00-25</span>
          </div>
        </div>
        <div className='con__social'>
          <a href='https://t.me/Mabrur_Travel'>
            <img src={Telegram} alt='' />
          </a>
          <a href='https://www.instagram.com/mabrurtraveluz/'>
            <img src={Instagram} alt='' />
          </a>
          <a href='https://www.youtube.com/@Mabrurtour'>
            <img src={Youtube} alt='' />
          </a>
        </div>
      </div>
      <div className='con__left'>
        <div className='con__name'>
          <input className='con__inputn' type='text' placeholder='Your name' />
          <input
            className='con__inputt'
            type='text'
            placeholder='Phone number'
          />
        </div>
        <div className='con__adress'>
          <input
            type='email'
            className='con__inpute'
            placeholder='Your email address'
          />
        </div>
        <div className='con__comment'>
          <textarea
            className='con__area'
            name=''
            id=''
            placeholder='Write your message here'
          />
        </div>
        <div className='con__send'>
          {/* <div className='con_attach '>
            <form method='POST' action='https://checkout.paycom.uz'>
              {' '}
              <input
                type='hidden'
                name='merchant'
                value='643913371decbc610d1bc63d'
              />{' '}
              <input type='hidden' name='amount' value='60000000' />{' '}
              <button type='submit' className='payme_btn'>
                {' '}
                <img
                  width={'100%'}
                  height='100%'
                  style={{ overflow: 'hidden' }}
                  src={payme}
                />{' '}
              </button>{' '}
            </form>
          </div> */}
          <div className='con__submit'>
            <Button
              width={'180px'}
              height={'53px'}
              radius={'8px'}
              color={'blue'}
              fontSize={'17px'}
              onClick={() => window.location.reload()}
            >
              Send message
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
