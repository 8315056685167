import kalta from '../assets/image/kalta.png';
import mazoley from '../assets/image/mazoley.png';
import mirarab from '../assets/image/mirarab.png';
import registon from '../assets/image/registon.png';

export const papular = [
  {
    path: registon,
    title: 'Registon',
    place: 'Samarqand',
    price: '150 000',
  },
  {
    path: mirarab,
    title: 'MirArab',
    place: 'Buxoro',
    price: '170 000',
  },
  {
    path: kalta,
    title: 'KaltaMinor',
    place: 'Xiva',
    price: '200 000',
  },
  {
    path: mazoley,
    title: 'A.Yassaviy',
    place: 'Turkiston',
    price: '180 000',
  },
];
