import React, { useRef } from 'react';
import About from '../../components/AboutUs';
import Asked from '../../components/AskedQuestions';
import Contact from '../../components/Contact';
import Destination from '../../components/Destination';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import NextPlanning from '../../components/NextPlanning';
import Recently from '../../components/Recently';
import SectionRemember from '../../components/SectionRemember';
import './style.css';

const Main = ({ click, refs }) => {
  const { handleClick, handleClick1, handleClick2, handleClick3 } = click;
  const { myRef, myRef1, myRef2, myRef3 } = refs;
  // const myRef = useRef(null);
  // const myRef1 = useRef(null);
  // const myRef2 = useRef(null);
  // const myRef3 = useRef(null);
  // const handleClick = () => {
  //   if (myRef.current) {
  //     window.scrollTo({
  //       top: myRef.current.offsetTop,
  //       behavior: 'smooth',
  //     });
  //   }
  // };
  // const handleClick1 = () => {
  //   if (myRef1.current) {
  //     window.scrollTo({
  //       top: myRef1.current.offsetTop,
  //       behavior: 'smooth',
  //     });
  //   }
  // };
  // const handleClick2 = () => {
  //   if (myRef2.current) {
  //     myRef2.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  // const handleClick3 = () => {
  //   if (myRef3.current) {
  //     myRef3.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  return (
    <>
      <Navbar
        click={{ handleClick, handleClick1, handleClick2, handleClick3 }}
      />
      <SectionRemember />
      <div className='container'>
        <Destination refMy={myRef2} />
        <About refMy={myRef1} />
      </div>
      {/* <div>
        <NextPlanning refMy={myRef3} />
      </div> */}
      <div className='container'>
        <Recently />
        {/* <Asked /> */}
        <Contact refMy={myRef} />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Main;
