import kalta from '../assets/image/makka.jpg';
import kalta2 from '../assets/image/madina.jpeg';
import kalta3 from '../assets/image/turkey.jpg';

export const panextpular = [
  {
    path: kalta,
    title: 'Umra uchun - Hamyonbop tarifi',
    description: `Samarqand_des`,
    time: '14 kunlik ziyorat - 1100$',
  },
  {
    path: kalta2,
    title: 'Umra uchun - Mumtoz tarifi',
    description: `Buhoro_des`,
    time: '14 kunlik ziyorat - 1250$',
  },
  {
    path: kalta3,
    title: 'Turkiyaga sayohat',
    description: `Khiva_des`,
    time: '10 kunlik ziyorat - 700$',
  },
];
