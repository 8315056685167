import React from 'react';
import './style.css';
import Telegram from '../../assets/icons/Telegram.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Youtube from '../../assets/icons/yotube.svg';
import Vector from '../../assets/icons/Vector.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='foo__container hidden-class'>
      <div className='foo__list'>
        <div className='foo__social'>
          <a href='https://t.me/Mabrur_Travel'>
            <img src={Telegram} alt='' />
          </a>
          <a href='https://www.instagram.com/mabrurtraveluz/'>
            <img src={Instagram} alt='' />
          </a>
          <a href='https://www.youtube.com/@Mabrurtour'>
            <img src={Youtube} alt='' />
          </a>
        </div>
        <span>Services</span>
        <span>Industries</span>
        <span>Partners</span>
        <span>Blog</span>
        <span>Contact us</span>
      </div>
    </div>
  );
};

export default Footer;
