import React from 'react';
import img1 from '../../assets/image/Bukhara.jpg';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Second = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  return (
    <div className='max-w-[741px] mt-32 m-auto div-cont'>
      <div className='flex self-center text-2xl div1 '>
        <h1 className='text-2xl font-normal'>Payment </h1> -{'     '}
        <h1 className='text-color mr-2'> 240 000 so'm</h1> {`  /  `}{' '}
        <p className='ml-2'>7 days</p>
      </div>
      <div className='gap-[16px] mt-[15px]  div2'>
        <div className='w-100 max-w-[441px] max-h-[294px] img1 '> </div>
        <div className='div2hid'>
          <div className='img2'></div>
          <div className='img3'></div>
        </div>
      </div>
      <div className='mt-3 div3'>
        <div>
          <h1 className='text-2xl font-normal'>Registon, Samarqand</h1>
        </div>
        <div>
          <h4 className='text-xl font-normal mt-2'>
            {' '}
            Transport: Avtobus <br />
            Ketish vaqti : Tonggi 6:00
          </h4>
        </div>
        <div className='mt-3'>{t('Samarqand_des')}</div>
      </div>
      <button
        className='rem__first'
        style={{ marginTop: '20px', cursor: 'pointer' }}
        onClick={() => history('/')}
      >
        Orqaga qaytish
      </button>
    </div>
  );
};

export default Second;
