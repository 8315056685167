import React, { useState } from 'react';
import './style.css';
import Stars from '../../assets/icons/Stars.svg';
import Slider from 'react-slick';
import { panextpular } from '../../mock/recently';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, TextInput, Label } from 'flowbite-react';
import { toast } from 'react-toastify';
const Recently = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  // const navigated = () => {
  //   history('/info');
  // };
  const [isOpen, setIsOpen] = useState(false);

  const notify = () =>
    toast.success("Malumotlaringiz jo'natildi", { position: 'top-center' });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    notify();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dotsClass: 'slick-dots',
    arrows: false,

    customPaging: function (i) {
      return <div className='des__dots'></div>;
    },
    // centerPadding:'100px',
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,

          dotsClass: 'slick-dots',

          customPaging: function (i) {
            return <div className='des__dots'></div>;
          },
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,

          customPaging: function (i) {
            return <div className='des__dots'></div>;
          },
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,

          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          className: 'slider variable-width ',
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          adaptiveHeight: true,

          // centerMode: true,
          variableWidth: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  return (
    <>
      <div className='res__container hidden-class'>
        <div className='res__title'>
          <span className='text-color res__title--firs '>
            Bizning tur paketlar
          </span>{' '}
        </div>
        <div className='res__text'>Paketlardan birini tanlang va ma'lumotlaringizni qoldiring</div>
        <div className='res__card'>
          <Slider {...settings}>
            {panextpular.map((item, i) => (
              <div className='res__card--wrap' onClick={openModal}>
                <img className='res__card--header' src={item.path} />{' '}
                <div className='res__card--title' style={{ fontSize: '22px' }}>
                  {item.title}
                </div>
                <div className='res__card--stars'>
                  <span className='res__stars--text'>{item.time} </span>
                </div>
                {/* <div className='res__card--footer'>{t(item.description)}</div> */}
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Modal show={isOpen} onClose={closeModal}>
        <Modal.Header>Tur uchun joy banq qilish</Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='base' value='Ismingiz' />
              </div>
              <TextInput id='base' required type='text' sizing='md' />
            </div>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='base' value='Telfon raqamingiz' />
              </div>
              <TextInput id='base' required type='text' sizing='md' />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Ma'lumotlarni yuborish</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Recently;
