import React from 'react';
import './style.css';
import Location from '../../assets/icons/Location.svg';
import Slider from 'react-slick';
import { papular } from '../../mock/destination';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Destination = ({ refMy }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  // const navigated = () => {
  //   history('/info');
  // };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    dotsClass: 'slick-dots',
    customPaging: function (i) {
      return <div className='des__dots'></div>;
    },
    // centerPadding:'100px',
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 4,
          arrows: false,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          // dotsClass: 'dots-custom',
          customPaging: function (i) {
            return <div className='des__dots'></div>;
          },
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,

          customPaging: function (i) {
            return <div className='des__dots'></div>;
          },
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          className: 'slider variable-width',
          slidesToShow: 1,
          variableWidth: true,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className='des__container  ' ref={refMy}>
      <div className='des__text'>
        <span> {t('papular')} </span>
        <span className='text-color rem__text'> {t('destination')}</span>
      </div>
      <div className='des__slider'>
        <Slider {...settings}>
          {papular.map((item, i) => (
            <div className='des__card'>
              <div className='des__card--header'>
                {/* <div className='des__img--container'> */}
                <img
                  src={item.path}
                  className='des__img--container'
                  style={{ borderRadius: '32px' }}
                  alt=''
                />
                {/* </div> */}
              </div>
              <div className='des__card--title'>{t(item.title)}</div>
              <div className='des__card--place'>
                <img src={Location} alt='Location' />
                <p className='des__card--text'>{t(item.place)}</p>
              </div>
              <div className='des__card--footer'>
                <span className='text-color dec__price'>{item.price} so'm</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default forwardRef(Destination);
