import React, { useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import Info from './pages/Info';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const App = () => {
  const myRef = useRef(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);

  const handleClick = () => {
    if (myRef.current) {
      window.scrollTo({
        top: myRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  const handleClick1 = () => {
    if (myRef1.current) {
      window.scrollTo({
        top: myRef1.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  const handleClick2 = () => {
    if (myRef2.current) {
      myRef2.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleClick3 = () => {
    if (myRef3.current) {
      myRef3.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <Main
              click={{ handleClick, handleClick1, handleClick2, handleClick3 }}
              refs={{ myRef, myRef1, myRef2, myRef3 }}
            />
          }
        />
        <Route
          path='/info'
          element={
            <Info
              click={{ handleClick, handleClick1, handleClick2, handleClick3 }}
              refs={{ myRef, myRef1, myRef2, myRef3 }}
            />
          }
        />
      </Routes>
      <ToastContainer />
    </div>
  );
};

export default App;
