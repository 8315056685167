import React from 'react';
import { Button } from './style.js';
const Buttons = ({
  children,
  marginleft,
  marginright,
  margin,
  padding,
  color,
  fontSize,
  radius,
  width,
  height,
  onClick,
}) => {
  return (
    <Button
      width={width}
      marginleft={marginleft}
      marginright={marginright}
      margin={margin}
      padding={padding}
      radius={radius}
      height={height}
      onClick={onClick}
      color={color}
      fontSize={fontSize}
    >
      {children}
    </Button>
  );
};

export default Buttons;
