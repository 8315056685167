import React, { useState, useEffect } from 'react';
import './style.css';
import Phone from '../../assets/icons/Phone.svg';
import NavIcon from '../../assets/image/mabrur-removebg-preview.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ click }) => {
  const { handleClick, handleClick1, handleClick2, handleClick3 } = click;
  const [state, setstate] = useState(true);
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  // console.log(prop);
  const selectLanguage = (e) => {
    localStorage.setItem('language', e.target.value);
    i18n.changeLanguage(e.target.value);
    // window.location.reload();
    return () => {
      console.log(`language ${e.target.value} ga o'zgardi`);
    };
  };

  useEffect(() => {
    localStorage.getItem('language')
      ? i18n.changeLanguage(localStorage.getItem('language'))
      : localStorage.setItem('language', 'uz');
  }, []);

  return (
    <>
      <div className='nav__container hidden-class' id='container89'>
        <div className='nav__wrap container'>
          <div
            // style={{ cursor: 'pointer', height: '78px' }}
            onClick={() => history('/')}
          >
            <img src={NavIcon} style={{ cursor: 'pointer', height: '78px' }} />
          </div>
          <div>
            <div className='nav__link'>
              <div
                onClick={() => {
                  handleClick1();
                  console.log('dfsdfds');
                }}
              >
                {' '}
                <a className='div-hover'>{t('navlink1')}</a>
              </div>
              <div>
                <a className='div-hover' onClick={handleClick2}>
                  {t('navlink')}
                </a>
              </div>
              <div>
                <a className='div-hover' onClick={handleClick}>
                  {t('Contact')} {t('boglan')}
                </a>
              </div>
            </div>
          </div>
          <div className='nav__buttons'>
            <select
              onChange={selectLanguage}
              value={localStorage.getItem('language')}
              className='nav__select'
            >
              <option value='uz'>UZ</option>
              <option value='ru'>RU</option>
              <option value='en'>EN</option>
            </select>
            <div className='nav__imgWrap' onClick={handleClick}>
              <img src={Phone} />
              {t('Contact')} {t('boglan')}
            </div>
          </div>
        </div>
      </div>
      <div className='nav__responsive--container'>
        <div className='nav__res--top'>
          {/* <img src={NavIcon} alt='' /> */}
          <div>
            <img src={NavIcon} style={{ cursor: 'pointer', height: '78px' }} />
          </div>
          <div></div>
          <div>
            {' '}
            <select
              onChange={selectLanguage}
              value={localStorage.getItem('language')}
              className='nav__select'
            >
              <option value='uz'>UZ</option>
              <option value='ru'>RU</option>
              <option value='en'>EN</option>
            </select>
          </div>
        </div>
        <div className={`nav__res--bottom ${state && 'nav-res-hidden'}`}>
          <div>fsdfds</div>
          <div>dsfdsf</div>
          <div>sdfdsf</div>
          <div>sdfdsf</div>
          <div>dsfdsf</div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
