import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Second from '../../components/second_page';

const Info = ({ click }) => {
  const { handleClick, handleClick1, handleClick2, handleClick3 } = click;

  return (
    <div className='h-[100vh] ali'>
      <Navbar
        click={{ handleClick, handleClick1, handleClick2, handleClick3 }}
      />
      <div className='flex flex-col  h-[100vh]'>
        <Second />
        <Footer />
      </div>
    </div>
  );
};

export default Info;
