import styled from 'styled-components';

export const Button = styled.button`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height};
  background: #ffffff;
  padding: ${({ padding }) => padding};
  border-radius: ${({ radius }) => radius};
  color: ${({ color }) => (color == 'blue' ? '#1A5EFF' : '#FFFFFF')};
  margin-right: ${({ marginright }) => marginright};
  margin-left: ${({ marginleft }) => marginleft};
  margin: ${({ margin }) => margin};
  background-color: ${({ color }) => (color == 'blue' ? '#FFFFFF' : '#1A5EFF')};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: center;
  border: 1px solid #1a5eff;
  white-space: nowrap;
  @media screen and (max-width: 500px) {
    border-radius: 6px;
  }
  &hover {
    background: #154fd6;
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.95);
  }
`;
